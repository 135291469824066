import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
import { pixelValueToRem } from '../../lib/formatGraphics';
import { isReactMouseEvent } from '../../lib/helpers/event';
import { Text } from '../Text/Text';
import './GraphGridNew.scss';

export type TGraphGridNewHorizontalLine = {
  type?: 'dotted' | 'dotted-thin' | 'bold' | 'hidden';
  normalizedY: number;
};

export type TGraphGridNewVerticalLine = {
  type?: 'dotted' | 'dotted-thin' | 'bold' | 'hidden';
  normalizedX: number;
};

type THoverCircle = {
  className: string;
  normalizedX: number;
  // The y coordinate is optional because we handle missing y coordinates in the GraphLine
  // component and it's more user friendly to just make it possible to create hover circle
  // objects based on the line coordinates without having to check if the y coordinate exists.
  normalizedY?: number;
};

type THoverImage = {
  className: string;
  // The y coordinate is optional because we handle missing y coordinates in the GraphLine
  // component and it's more user friendly to just make it possible to create hover image
  // objects based on the line coordinates without having to check if the y coordinate exists.
  normalizedPositions: [{ normalizedX: number; normalizedY?: number }];
  href: string;
  width?: number;
  height?: number;
  /** Defaults to translate(-50%, -50%).
   *
   * Need to specify units.
   * (e.g translate(20px, 20px))
   */
  transform?: string;
};

interface IProps {
  height: number;
  horizontalLines: TGraphGridNewHorizontalLine[];
  verticalLines: TGraphGridNewVerticalLine[];
  children?: React.ReactNode;
  childrenBehindVerticalLines?: React.ReactNode;
  graphText?: string;
  hoverType: 'tick' | 'column';
  hoverIndex?: number;
  hoverCircles?: THoverCircle[];
  hoverImage?: THoverImage;
  hideHoverTickLine?: boolean;
  draggable?: boolean;
  onHover: (options: { index: number; x: number; y: number }) => void;
  onHoverCancel?: () => void;
}

export function GraphGridNew(props: IProps) {
  const {
    height,
    horizontalLines,
    verticalLines,
    children,
    childrenBehindVerticalLines,
    graphText,
    hoverType,
    hoverIndex,
    hoverCircles,
    hoverImage,
    hideHoverTickLine = false,
    draggable = false,
    onHover,
    onHoverCancel
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  // Cancel hover when the user scrolls the page to prevent any hover cards
  // from lingering on the screen when scrolling on mobile.
  // We want to cancel the hover both when the user scrolls the viewport
  // vertically, and when they scroll the graph horizontally.
  useEffect(() => {
    if (onHoverCancel == null) {
      return;
    }

    // Debounce the scroll event listener
    // so we don't call onHoverCancel() too often.
    const handleScroll = debounce(onHoverCancel, 100, { leading: true });

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      handleScroll.cancel();
    };
  }, [onHoverCancel]);

  function handleMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) {
    if (ref.current == null) {
      return;
    }

    const clientX = isReactMouseEvent(event) ? event.clientX : event.touches[0].clientX;
    const clientY = isReactMouseEvent(event) ? event.clientY : event.touches[0].clientY;

    const rect = ref.current.getBoundingClientRect();
    if (clientX < rect.left || clientX > rect.right) {
      return;
    }

    const offsetX = clientX - rect.left;
    const normalizedX = offsetX / rect.width;

    const newHoverIndex =
      hoverType === 'tick'
        ? getTickIndexByX({ normalizedX, verticalLines })
        : getColumnIndexByX({ normalizedX, verticalLines });

    if (newHoverIndex == null) {
      if (onHoverCancel != null) {
        onHoverCancel();
      }

      return;
    }

    if (newHoverIndex !== hoverIndex) {
      onHover({ index: newHoverIndex, x: clientX, y: clientY });
    }
  }

  function handleMouseLeave() {
    if (onHoverCancel != null) {
      onHoverCancel();
    }
  }

  return (
    <div
      className="graph-grid-new"
      data-testid="graph-grid-new"
      data-is-draggable={draggable}
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchMove={draggable ? handleMouseMove : undefined}
      onTouchEnd={draggable ? handleMouseLeave : undefined}
    >
      {graphText ? (
        <Text size="4" className="graph-grid-new__text" color="secondary">
          {graphText}
        </Text>
      ) : null}
      <svg
        className="graph-grid-new__grid"
        width="100%"
        height={height}
        // Not all browsers support rem height in the SVG attribute,
        // e.g. Firefox 68 and older, and Safari complains about it
        // in devtools even though it appears to support it.
        // To be safe we use an inline style to set the height in rem instead.
        style={{ height: pixelValueToRem(height) }}
      >
        {/*
          Render hover column first so the hover column is rendered behind
          the grid lines and other content.
        */}
        {hoverType === 'column' && hoverIndex != null && (
          <rect
            className="graph-grid-new__column-highlight"
            x={`${verticalLines[hoverIndex].normalizedX * 100}%`}
            y="0"
            width={`${(verticalLines[hoverIndex + 1].normalizedX - verticalLines[hoverIndex].normalizedX) * 100}%`}
            height="100%"
          />
        )}

        {horizontalLines.map(horizontalLine => {
          if (horizontalLine.type === 'hidden') {
            return null;
          }

          return (
            <line
              className={classNames({
                'graph-grid-new__line': true,
                'graph-grid-new__line--bold': horizontalLine.type === 'bold',
                'graph-grid-new__line--dotted': horizontalLine.type === 'dotted',
                'graph-grid-new__line--dotted-thin': horizontalLine.type === 'dotted-thin'
              })}
              key={horizontalLine.normalizedY}
              x1="0"
              x2="100%"
              y1={`${(1 - horizontalLine.normalizedY) * 100}%`}
              y2={`${(1 - horizontalLine.normalizedY) * 100}%`}
            />
          );
        })}

        {childrenBehindVerticalLines}

        {verticalLines.map((verticalLine, index) => {
          if (verticalLine.type === 'hidden') {
            return null;
          }

          if (hoverType === 'tick' && hoverIndex === index) {
            return null;
          }

          return (
            <line
              className={classNames('graph-grid-new__line', {
                'graph-grid-new__line--bold': verticalLine.type === 'bold',
                'graph-grid-new__line--dotted': verticalLine.type === 'dotted',
                'graph-grid-new__line--dotted-thin': verticalLine.type === 'dotted-thin'
              })}
              key={verticalLine.normalizedX}
              x1={`${verticalLine.normalizedX * 100}%`}
              x2={`${verticalLine.normalizedX * 100}%`}
              y1="0"
              y2="100%"
            />
          );
        })}

        {children}

        {/* Render hover tick in front of the graph's bars, lines, etc. */}
        {hoverType === 'tick' && hoverIndex != null && hideHoverTickLine === false && (
          <line
            className={classNames('graph-grid-new__line', {
              'graph-grid-new__line--bold': true,
              'graph-grid-new__line--dotted': true
            })}
            x1={`${verticalLines[hoverIndex].normalizedX * 100}%`}
            x2={`${verticalLines[hoverIndex].normalizedX * 100}%`}
            y1="0"
            y2="100%"
          />
        )}

        {/* Render hover sun in front of the hover tick line */}
        {hoverImage != null &&
          hoverImage.normalizedPositions.map((hoverImagePosition, index) => {
            if (hoverImagePosition.normalizedY == null) {
              return null;
            }
            return (
              <image
                key={index}
                className="graph-grid-new__hover-image"
                x={`${hoverImagePosition.normalizedX * 100}%`}
                y={`${(1 - hoverImagePosition.normalizedY) * 100}%`}
                href={hoverImage.href}
                height={hoverImage.height != null ? hoverImage.height : undefined}
                width={hoverImage.width != null ? hoverImage.width : undefined}
                style={{ transform: hoverImage.transform != null ? hoverImage.transform : 'translate(-50%, -50%' }}
              />
            );
          })}
        {hoverCircles != null &&
          hoverCircles.map((hoverCircle, index) => {
            if (hoverCircle.normalizedY == null) {
              return null;
            }

            return (
              <circle
                key={index}
                className={classNames('graph-grid__circle-highlight', hoverCircle.className)}
                cx={`${hoverCircle.normalizedX * 100}%`}
                cy={`${(1 - hoverCircle.normalizedY) * 100}%`}
                r={5}
              />
            );
          })}
      </svg>
    </div>
  );
}

function getColumnIndexByX({
  normalizedX,
  verticalLines
}: {
  normalizedX: number;
  verticalLines: TGraphGridNewVerticalLine[];
}) {
  for (let i = 0; i < verticalLines.length - 1; i++) {
    const columnLeft = verticalLines[i].normalizedX;
    const columnRight = verticalLines[i + 1].normalizedX;

    if (normalizedX >= columnLeft && normalizedX <= columnRight) {
      return i;
    }
  }

  return undefined;
}

function getTickIndexByX({
  normalizedX,
  verticalLines
}: {
  normalizedX: number;
  verticalLines: TGraphGridNewVerticalLine[];
}) {
  if (verticalLines.length < 2) {
    return undefined;
  }

  for (let i = 0; i < verticalLines.length - 1; i++) {
    const currentTickX = verticalLines[i].normalizedX;
    const nextTickX = verticalLines[i + 1].normalizedX;

    if (normalizedX < currentTickX) {
      continue;
    }

    if (normalizedX > nextTickX) {
      continue;
    }

    const tickDistance = nextTickX - currentTickX;
    const deltaX = normalizedX - currentTickX;

    if (deltaX / tickDistance < 0.5) {
      return i;
    } else {
      return i + 1;
    }
  }

  return undefined;
}
