import debounce from 'lodash/debounce';

// Listen for scroll events on an element and determine if the element can be
// scrolled further left or right from its current horizontal scroll position.
// Returns a cancel function that stops listening for scroll events.
export function listenIsScrollableHorizontally(
  element: HTMLElement,
  callback: (isScrollable: { left: boolean; right: boolean }) => void
) {
  const handleScroll = debounce(
    () => {
      const { scrollLeft, scrollWidth, offsetWidth } = element;

      const scrollRight = scrollWidth - offsetWidth - scrollLeft;

      callback({
        left: scrollLeft > 0,
        right: scrollRight > 0
      });
    },
    100,
    { leading: true }
  );

  // Check once as soon as this helper is called
  handleScroll();

  element.addEventListener('scroll', handleScroll);

  return () => {
    element.removeEventListener('scroll', handleScroll);
    handleScroll.cancel();
  };
}
