import { ScrollShadow } from '../ScrollShadow/ScrollShadow';

interface IProps {
  position: 'left' | 'right';
  gridColumn: number;
  gridRowEnd: number;
  showScrollShadow: boolean;
  hasMinWidth: boolean;
}

export function GraphShellNew__YAxisBackground(props: IProps) {
  const { position, gridColumn, gridRowEnd, showScrollShadow, hasMinWidth } = props;

  return (
    <div
      className="graph-shell-new__y-axis-background"
      data-position={position}
      data-has-min-width={hasMinWidth}
      // Make the background as tall as the graph itself
      style={{ gridColumn, gridRow: `1 / ${gridRowEnd}` }}
    >
      <div className="graph-shell-new__scroll-shadow">
        <ScrollShadow position={position} visible={showScrollShadow} />
      </div>
    </div>
  );
}
