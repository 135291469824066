import { Icon } from '@nrk/yr-icons';
import { Text } from '../Text/Text';
import { IGraphShellNewYAxisRows, IGraphShellNewYAxisTicks, TGraphShellNewYAxisTick } from './GraphShellNew';

interface IProps {
  position: 'left' | 'right';
  gridColumn: number;
  gridRow: number;
  yAxis: IGraphShellNewYAxisTicks | IGraphShellNewYAxisRows;
  hasMinWidth: boolean;
  hideOnDesktop?: boolean;
}

export function GraphShellNew__YAxis(props: IProps) {
  const { position, gridColumn, gridRow, yAxis, hasMinWidth = false, hideOnDesktop } = props;

  // Convert rows to ticks so we only need code to position ticks
  const ticks: TGraphShellNewYAxisTick[] =
    yAxis.type === 'ticks'
      ? yAxis.ticks
      : yAxis.rows.map(row => ({
          normalizedY: row.normalizedMinY + (row.normalizedMaxY - row.normalizedMinY) / 2,
          label: row.label
        }));

  return (
    <div
      className="graph-shell-new__y-axis"
      style={{ gridColumn, gridRow }}
      data-position={position}
      // If we have multiple graphs on the same page that should all align they need to have the same y axis width
      data-has-min-width={hasMinWidth}
      data-hide-on-desktop={hideOnDesktop}
    >
      {ticks.map(tick => {
        // The offset is reversed because a tick with a `normalizedY` value of 0
        // should be positioned at the bottom of the y axis column.
        const offsetY = 100 * (1 - tick.normalizedY);

        return (
          <Text
            size="5"
            color="secondary"
            key={tick.normalizedY}
            className="graph-shell-new__y-axis-tick"
            style={{ top: `${offsetY}%` }}
          >
            {tick.label?.type === 'value' && <span className="graph-shell-new__y-axis-label">{tick.label.value}</span>}
            {tick.label?.type === 'icon' && (
              <Icon className="graph-shell-new__y-axis-icon" id={tick.label.id} size={1.5} />
            )}
          </Text>
        );
      })}
    </div>
  );
}
